import create from "zustand"
import Swiper from "swiper/types/swiper-class"
import {POPUP_BANNER_TYPES} from "./constants"

export interface iPopupBannerStore {
  sliderRef: Swiper
  isOpen?: boolean
  index: number
  type: string
  isOnHold: boolean
  isClosing: boolean
  closeTimer: number
  setIsOnHold: (value: boolean) => void
  setIsClosing: (value: boolean) => void
  setCloseTimer: (value: number) => void
  close: () => void
  setSliderRef: (value: Swiper) => void
  setIsOpen?: (value: boolean) => void
  setIndex: (value: number) => void
  setType: (value: string) => void
  setClose: (value: () => void) => void
  timerTick: () => void
  resetStore: () => void
}

const usePopupBannerStore = create<iPopupBannerStore>((set, get) => ({
  sliderRef: null,
  isOpen: false,
  index: 0,
  type: POPUP_BANNER_TYPES.NEWSPORTS,
  isOnHold: false,
  isClosing: false,
  close: null,
  closeTimer: 2,
  setCloseTimer: closeTimer => set({closeTimer}),
  setIsOnHold: isOnHold => set({isOnHold}),
  setIsClosing: isClosing => set({isClosing}),
  setSliderRef: sliderRef => set({sliderRef}),
  setIsOpen: isOpen => set({isOpen}),
  setIndex: index => set({index}),
  setType: type => set({type}),
  setClose: close => set({close}),
  timerTick: () => {
    const timer = get().closeTimer
    const second = timer - 1
    if (second >= 1) set({closeTimer: second})
    else {
      set({closeTimer: 0})
      get().close()
      setTimeout(()=> get().resetStore(),300)
    }
  },
  resetStore: () => {
    set({
      closeTimer: 2,
      isOpen: false,
      index: 0,
      isOnHold: false,
      isClosing: false,
      type: POPUP_BANNER_TYPES.NEWSPORTS,
    })
  },
}))

export default usePopupBannerStore
