import React, {CSSProperties, FC, memo, useContext} from "react"
import format from "date-fns/format"
import s from "./popup.module.scss"
import Layout from "components/Banner/PopupV3/Layout"
import CloseTimerButton from "components/Banner/PopupV3/CloseTimerButton"
import {useWindowSize} from "react-use"
import {changeTimeZone} from "screens/Casino/utils/helper"
import usePopupBannerStore from "components/Banner/PopupV3/usePopupBannerStore"
import usePopupBannerV4Store from "components/Banner/PopupV4/usePopupBannerStore"
import shallow from "zustand/shallow"
import {ThemeContext} from "context/ThemeContext"
import cx from "classnames"

interface iPopup {
  className?: string
  selectedPage?: string
}

interface CSSCustomProperties extends CSSProperties {
  "--readbuttonBackground"?: string
  "--buttonLabel"?: string
}

const Popup: FC<iPopup> = (): JSX.Element => {
  const {width} = useWindowSize()
  const isMobile = width < 674

    const {theme} = useContext(ThemeContext)
    const custom_colors: CSSCustomProperties = {
      "--readbuttonBackground": theme?.colors.headerHoverColor,
      "--buttonLabel": theme?.colors.buttonLabel,
    }
  

  const [isOpen] = usePopupBannerV4Store(store => [store.isOpen], shallow)

  const handleCloseForToday = () => {
    const popup = usePopupBannerStore.getState()
    popup.close()
    const koreanDateNow = changeTimeZone(new Date(), "Asia/Seoul")
    const dateNow = format(koreanDateNow, "yyyy-MM-dd")
    localStorage.setItem("home_banner_v3_ignore", dateNow)
  }

  return (
    <div className={cx(s.container, {[s.show]: !isOpen})}>
      <Layout />
      {!isOpen && (
        <div
          role="button"
          className={s.closeBtn}
          onClick={() => handleCloseForToday()}
          style={custom_colors}
        >
          <span>오늘하루 이 창을 열지 않음</span>
        </div>
      )}
      {isMobile && <CloseTimerButton />}
    </div>
  )
}

export default memo(Popup)
